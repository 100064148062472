<template>
    <div>
        <h1>BIENVENIDO A OPTIMANIA</h1>
        <router-link :to="{name:'inicioWeb'}">Incio</router-link> | 
        <router-link :to="{name:'productosWeb'}">Productos</router-link> | 
        <router-link :to="{name:'blogWeb'}">Blog</router-link> 
        <router-view></router-view>
    </div>
</template>

<script>
export default {
   
}
</script>